import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import MG1 from "../assets/img/MG1.png";
import MG2 from "../assets/img/MG2.png";
import { useDispatch, useSelector } from "react-redux";
import { getStreet, } from "../store/actions/newStraatAction";
import { postLead, postLead2, setErrorResponse } from "../store/actions/postLeadAction";
import { useLocation } from "react-router-dom";

const FormProefabonnement = () => {
  const { error_response } = useSelector((state) => state.response);
  const { create_response } = useSelector((state) => state.response);

  const { newStreet, houseNumber } = useSelector((state) => state.strait);
  console.log(houseNumber)
  const [addressLoader, setAddressLoader] = useState(false);
  const [postCode, setPostCode] = useState("");
  const [homeNumber, setHomeNumber] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [telephoneNumber, setTelePhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [Tussenv, setTussenv] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [addState, setAddState] = useState(false);
  const [streetLoader, setStreetLoader] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  let dispatch = useDispatch();
  const location = useLocation().search;
  const publisher_id = new URLSearchParams(location).get("siteid");
  const site_subid = new URLSearchParams(location).get("oa_id");

  const handleFormData = (e) => {
    e.preventDefault();
    const homeNumberWithoutPunctuation = homeNumber.replace(/[\s-]/g, "");
    const phoneNumberWithoutPunctuation = telephoneNumber
      .replace(/[\s-]/g, "")
      .replace(/^00(\d+)$/, "$1");

    let obj = {
      language: "nl_NL",
      firstname: firstName,
      // Tussenv,
      lastname: Tussenv + " " + lastName,
      email,
      // date_of_birth: dob,
      street: streetNumber,
      house_number: houseNumber,
      zip: postCode,
      phone_number: phoneNumberWithoutPunctuation,
      city,
      answers: ["3274"],
      site_subid,
      publisher_id,
      // site_custom_name: "maxmagazine_proefnummer",
      site_custom_name: "proefabonnement_1",
      site_custom_url: "https://maxmagazine.gratismeedoen.nl/",
    };
    if (
      checkVoorNaamRegex(firstName) === false &&
      checkLastNaamRegex(lastName) === false &&
      checkEmailRegex(email) === false &&
      //   checkhomeNumberRegex(homeNumber) === false &&
      checkTelefoornNummerRegex(telephoneNumber) === false &&
      // date(Date) === false &&
      checkZipRegex(postCode) === false
    ) {
      dispatch(postLead2(obj));
      // console.log(obj, new Date(dob).toLocaleDateString("en-CA"));
      console.log(obj);
    }
  };

  // Regex
  const checkVoorNaamRegex = (firstName) => {
    let validName = /^[a-zA-Z\s.,'-]{1,30}$/;
    if (firstName != "") {
      if (validName.test(firstName)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const checkLastNaamRegex = (lastName) => {
    let validName = /^[a-zA-Z\s.,'-]{1,30}$/;
    if (lastName != "") {
      if (validName.test(lastName)) {
        // console.log(lastName);
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (postCode && !checkhomeNumberRegex(homeNumber) && homeNumber) {
      const homeNumberWithoutPunctuation = homeNumber.replace(/[\s-]/g, "");
      let obj = {
        zip: postCode,
        house_number: homeNumberWithoutPunctuation,
      };
      setStreetLoader(true);
      setAddressLoader(true);
      dispatch(
        getStreet(obj, setAddState, () => {
          setStreetLoader(false);
          setAddressLoader(false);
        })
      );
    }
  }, [homeNumber]);
  useEffect(() => {
    if (newStreet) {
      setStreetNumber(newStreet?.street);
      setCity(newStreet?.settlement);
      setPostCode(newStreet?.postalCode);
      setHomeNumber(newStreet?.streetNumber?.toString());
    }
  }, [newStreet]);
  const checkEmailRegex = (email) => {
    let validName = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/;
    if (email != "") {
      if (validName.test(email)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const checkTelefoornNummerRegex = (telephoneNumber) => {
    let validName = /^(0|\+?31|00\s?31)([-\s]?\d[-\s]?){7,8}\d$/;
    // 10
    if (telephoneNumber != "") {
      if (validName.test(telephoneNumber)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const checkZipRegex = (postCode) => {
    let zipRegex = /^([0-9]{4}\s?[a-zA-Z]{2})$/;
    if (postCode != "") {
      if (zipRegex.test(postCode)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const checkhomeNumberRegex = (homeNumber) => {
    let houseRegex = /^(?=.*\d)[0-9A-Za-z\s-]+$/;
    if (homeNumber != "") {
      if (houseRegex.test(homeNumber)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (newStreet) {
      setStreetNumber(newStreet?.street);
      setCity(newStreet?.settlement);
    }
  }, [newStreet]);
  useEffect(() => {
    if (create_response?.profile?.id) {
      setImageUrl(
        `https://republish.gratismeedoen.nl/m/6360/250128ec19ad/?event=6415&unique_conversion_id=${create_response?.profile?.id}`
      );
    }
  }, [create_response?.profile?.id]);

  return (
    <>
      {create_response?.profile ? (
        <>
        <Card className='thankYouBg'>
            <div className="thankYouCard">
              <h3 className="text-center px-5">
              Hartelijk dank voor uw aanvraag van een proefabonnement van MAX Magazine. Uw eerste editie wordt binnen 1,5 week bij het door u opgegeven adres bezorgd. De andere edities volgen in de weken daarop.


              </h3>
              {imageUrl && (
                <div className="d-flex justify-content-center">
                  <img
                    className="text-site-secondary"
                    referrerpolicy={"no-referrer-when-downgrade"}
                    src={imageUrl}
                    style={{
                      width: "100px",
                      height: "100px",
                      border: "0px",
                    }}
                  />
                </div>
              )}
          </div>
          </Card>
        </>
      ) : (
        <Card className="LoaderModalBg3">
          <Row>
            <Col>
              <Row className="row justify-content-center">
                <div className="formLeftCol2 col-md-12 col-lg-6 col-xl-6 ">
                  <Card className="formSection2">
                    <h4 className="p-3 ">
                      <span className="greenText">Gefeliciteerd!</span> Naar
                      wie mogen we de 4 gratis proefnummers sturen?
                    </h4>
                    <FormGroup className="mx-3">
                      <Label className="fw-bold" for="firstName">
                        Voornaam
                      </Label>
                      <Input
                        id="firstName"
                        className="Input-border"
                        name="firstName"
                        placeholder="Pieter"
                        type="text"
                        required
                        value={firstName}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                          error_response?.firstname &&
                            dispatch(setErrorResponse());
                        }}
                        invalid={
                          checkVoorNaamRegex(firstName) ||
                          error_response?.firstname
                        }
                      />
                      <FormFeedback>Voornaam niet correct</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mx-3">
                      <Label className="fw-bold" for="lastName">
                        Achternaam
                      </Label>
                      <Input
                        id="lastName"
                        className="Input-border"
                        name="lastName"
                        placeholder="Vresens"
                        type="text"
                        required
                        value={lastName}
                        invalid={
                          checkLastNaamRegex(lastName) ||
                          error_response?.lastname
                        }
                        onChange={(e) => {
                          setLastName(e.target.value);
                          error_response?.lastname &&
                            dispatch(setErrorResponse());
                        }}
                      />
                      <FormFeedback>Achternaam niet correct</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mx-3">
                      <Label className="fw-bold" for="Email">
                        E-mailadres
                      </Label>
                      <Input
                        id="Email"
                        name="email"
                        className="Input-border"
                        placeholder="naam@email.nl"
                        type="email"
                        required
                        value={email}
                        invalid={
                          checkEmailRegex(email) || error_response?.email
                        }
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <FormFeedback>E-mailadres niet correct</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mx-3">
                      <Label className="fw-bold" for="phone_number">
                        Telefoonnummer
                      </Label>
                      <Input
                        id="phone_number"
                        name="phone_number"
                        required
                        className="Input-border"
                        placeholder="0611223344"
                        value={telephoneNumber}
                        invalid={
                          checkTelefoornNummerRegex(telephoneNumber) ||
                          error_response?.phone_number
                        }
                        onChange={(e) => {
                          setTelePhoneNumber(e.target.value);
                          error_response?.phone_number &&
                            dispatch(setErrorResponse());
                        }}
                      />
                      <FormFeedback>Telefoonnummer niet correct</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mx-3">
                      <Label className="fw-bold">Adres</Label>
                      <Input
                        name="address"
                        className="Input-border"
                        placeholder="Adres"
                        required
                        value={newStreet.street + " " + houseNumber + ", " + newStreet.municipality}
                        readOnly
                      />
                    </FormGroup>
                    <div className="m-3 FormBtn">
                      <button
                        className=""
                        type="button"
                        onClick={handleFormData}
                      >
                        Bevestigen
                        <i className="fa fa-solid fa-chevron-right mx-3"></i>
                      </button>
                      <p className="px-2 pt-2">
                        Door op "Bevestigen" te klikken, gaat u er mee akkoord
                        dat MAX u eenmalig per telefoon en e-mail mag benaderen
                        met een passende aanbieding.<b> U zit nergens aan vast!</b>  Er
                        is 1 proefabonnement beschikbaar per adres.
                      </p>
                    </div>
                  </Card>
                </div>
                <div className="ImgSection2 col-md-12 col-lg-6 col-xl-6 img-fluid d-md-block d-none mt-5 ">
                  <Card className="formImgCard">
                    {newStreet?.country && (
                      <>
                        <h4>
                          <b>Het proefabonnement is beschikbaar voor <br />{" "}
                          <span className="greenText">
                            {newStreet.street + " " + houseNumber}.
                          </span>
                          </b>
                        </h4>
                        <iframe
                          width="100%"
                          height="100%"
                          frameborder="0"
                          scrolling="no"
                          marginheight="0"
                          marginwidth="0"
                          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCIUep3Zbrrok-Wm4Nsj_nwgWKZXIVUjBo&q=${newStreet.lat},${newStreet.lng}&center=${newStreet.lat},${newStreet.lng}&zoom=18`}
                        ></iframe>
                      </>
                    )}
                  </Card>
                  <div className="formImg">
                    <img
                      src={MG2}
                      width={100}
                      height={100}
                      className="img-fluid"
                    />
                    <img
                      src={MG1}
                      width={100}
                      height={100}
                      className="img-fluid formImg2"
                    />
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

export default FormProefabonnement;
