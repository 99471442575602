import React from 'react'
import { useSelector } from 'react-redux';
import { Spinner, Card, Row, Col } from 'reactstrap';

const LoaderModal = () => {

    return (

        <>
            <Card className='LoaderModalBg'>
                <Card className='loaderModalCard shadow p-3 mb-5 bg-white rounded'>
                    <h2 className='text-center'>Wij controleren nu uw adres...</h2>
                    <Row>
                        <Col lg="12" className='loader'>
                    <Spinner className='greenText spinner' />
                    </Col>
                    </Row>
                </Card>
            </Card>
        </>
    )
}

export default LoaderModal
