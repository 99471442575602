import React from 'react'
import { Spinner, Card, Row, Col } from 'reactstrap';
import successImg from "../assets/img/successIcon.png"
import { useSelector } from 'react-redux';
import checkMark from "../assets/videos/checkMark.mp4"
const GratisProefabonnement = () => {
    const { newStreet, houseNumber } = useSelector((state) => state.strait)
console.log(houseNumber)
    return (

        <>
            <Card className='LoaderModalBg'>
                <Card className='loaderModalCard2 shadow p-3 mb-5 bg-white rounded'>
                <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M59.5622 29.49L36.8928 51.7148L26.4524 41.4793C25.1108 40.164 22.9264 40.164 21.5848 41.4793C20.2432 42.7945 20.2432 44.9361 21.5848 46.2514L34.4504 58.8646C35.1212 59.5222 35.9984 59.8595 36.8756 59.8595C37.7528 59.8595 38.63 59.5222 39.3008 58.8646L64.3953 34.262C65.7369 32.9467 65.7369 30.8051 64.3953 29.49C63.0537 28.1747 60.9038 28.1747 59.5622 29.49Z" fill="#1CC500"/>
<path d="M42.9997 0.334534C19.2466 0.334534 0 19.2038 0 42.4911C0 65.7782 19.2466 84.6476 42.9997 84.6476C66.7527 84.6476 85.9993 65.7782 85.9993 42.4911C85.9993 19.2038 66.7527 0.334534 42.9997 0.334534ZM42.9997 77.9025C23.0822 77.9025 6.87995 62.018 6.87995 42.4911C6.87995 22.9642 23.0822 7.07957 42.9997 7.07957C62.9171 7.07957 79.1193 22.9642 79.1193 42.4911C79.1193 62.018 62.9171 77.9025 42.9997 77.9025Z" fill="#1CC500"/>
</svg><h3>Er is een gratis proefabonnement beschikbaar voor <br /><span className='greenText'>{newStreet.street + " " + houseNumber}</span></h3>
                </Card>
            </Card>
        </>
    )
}

export default GratisProefabonnement
