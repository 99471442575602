import React, { useEffect, useState } from 'react';
import { Card, Col, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import image from "../assets/img/Group33.png";
import LoaderModal from '../components3/LoaderModal';
import BottomSecProefabonnement from './BottomSecProefabonnement';
import GratisProefabonnement from './GratisProefabonnement'
import FormProefabonnement from './FormProefabonnement'
import { getStreet, setErrorResponseStreet, setHouseNumber } from '../store/actions/newStraatAction';
import { useDispatch, useSelector } from 'react-redux';
const HeroSectionProefabonnement = ({ steps, setSteps }) => {
    const { newStreet } = useSelector((state) => state.strait)
    console.log(newStreet)
    let dispatch = useDispatch()
    const [postCode, setPostCode] = useState('');
    const [huisNummer, setHuisNummer] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [addState, setAddState] = useState(false)
    // console.log(address)

    const checkZipRegex = (postCode) => {
        let zipRegex = /^([0-9]{4}\s?[a-zA-Z]{2})$/;
        if (postCode != "") {
            if (zipRegex.test(postCode)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };
    const checkHouseNumberRegex = (houseNumber) => {
        let houseRegex = /^(?=.*\d)[0-9A-Za-z\s-]+$/;
        if (houseNumber != "") {
            if (houseRegex.test(houseNumber)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    const handleButtonClick = () => {
        if (!checkZipRegex(postCode) && !checkHouseNumberRegex(huisNummer)) {
            setSteps(2);
            let obj = {
                zip: postCode,
                house_number: huisNummer,
            };
            dispatch(setHouseNumber(obj))
            dispatch(
                getStreet(obj, setAddState, (res) => {
                    if (res == "success") {

                    }
                }))


        } else {
            alert('Please fill out both Postcode and Huisnr.');
        }
    };

    useEffect(() => {
        if (newStreet?.country && steps == 2) {
            const timer = setTimeout(() => {
                setSteps(3);
            }, 2000);

            return () => clearTimeout(timer);
        } else if (steps === 3) {
            const timer = setTimeout(() => {
                setSteps(4);
            }, 2000);

            return () => clearTimeout(timer);
        }
        else if (newStreet == "data") {
            setSteps(1);
        }

    }, [newStreet, steps]);

    return (
        <>
            {steps == 2 ? (
                <LoaderModal />
            ) : steps == 3 ? (
                <GratisProefabonnement />
            ) : steps == 4 ? (
                <FormProefabonnement />
            ) : (
                <>
                    <Row className="row justify-content-center">
                        <div className="HeroLeftCol2 col-md-12 col-sm-12 col-lg-6 mb-sm-4">
                            <h1 className="mb-4 mt-5">
                                Actie! <span>GRATIS proefabonnement van 4 weken </span>
                            </h1>
                            <div className='d-flex checkIconText'>
                                <p ><i className="fa fa-check" aria-hidden="true"></i><span className='mx-2'><b>Gratis thuisbezorgd</b></span></p>
                                <p className='mx-4'><i className="fa fa-check" aria-hidden="true"></i><span className='mx-2'><b>Stopt automatisch</b></span></p>
                            </div>
                            <h6><b>Controleer hier of uw adres</b> in aanmerking komt voor het gratis proefabonnement:</h6>
                            <Form onSubmit={handleButtonClick} >
                                <Card className="d-flex  align-items-center shadow heroCard2">
                                    <Col sm="6" className="pl-0">
                                        <div className="w-100 h-100 d-flex header-fileds">
                                            <FormGroup >
                                                <Input
                                                    type="text"
                                                    className="py-4"
                                                    required
                                                    placeholder="Postcode"
                                                    color="black"
                                                    value={postCode}
                                                    invalid={
                                                        checkZipRegex(postCode) || newStreet == "data"
                                                    }
                                                    maxLength={7}
                                                    onChange={(e) => {
                                                        setPostCode(e.target.value);
                                                        { newStreet == "data" && dispatch(setErrorResponseStreet()) }
                                                    }}
                                                />
                                                <FormFeedback className='ErrorResponse'>Postcode niet correct</FormFeedback>
                                            </FormGroup>
                                            <FormGroup >
                                                <Input
                                                    type="text"
                                                    className="py-4 ml-1"
                                                    required
                                                    placeholder="Huisnr."
                                                    color="black"
                                                    maxLength={7}
                                                    value={huisNummer}
                                                    invalid={checkHouseNumberRegex(huisNummer) || newStreet == "data"}
                                                    onChange={(e) => {
                                                        setHuisNummer(e.target.value);
                                                        { newStreet == "data" && dispatch(setErrorResponseStreet()) }
                                                    }}
                                                />
                                                <FormFeedback className='ErrorResponse'>Huisnummer niet correct</FormFeedback>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col sm="6" className="p-0">
                                        <button
                                            className="hero-button2  d-flex flex-nowrap justify-content-center align-items-center text-nowrap py-3"
                                            type="submit"
                                        >
                                            Controleer {" "}
                                            <i className="fa fa-solid fa-chevron-right mx-3"></i>
                                        </button>
                                    </Col>
                                </Card>

                            </Form>
                        </div>
                        <div className="HeroRightCol2 col-md-12 col-lg-6 my-md-0   ">


                            <img
                                src={image}
                                alt="img"
                                className=" desktop "
                            />
                        </div>
                    </Row>
                    <BottomSecProefabonnement />
                </>
            )}
        </>
    );
};

export default HeroSectionProefabonnement;
