import React from 'react'
import GreenTick from "../assets/img/g52-9-5.svg";
import { Card, Row } from 'reactstrap';
import magazine from "../assets/img/magzine3.png"
const BottomSecProefabonnement = () => {
    let data = [
        "Actuele artikelen over gezondheid, financiën en levensvragen",
        "Aangrijpende en waargebeurde verhalen",
        "Tips voor dagjes uit, wonen en het huishouden",
        "Leuke puzzels, voordelen en winacties",
      ];
  return (
    <div>
      <Card className="bottomSection">
        <Row className="row bottomSectionRow justify-content-center">
          <div className="bottomLeftCol  img-fluid img-md-fixed col-lg-6  col-md-12 col-sm-12 order-md-1 order-2">
            <img
              src={magazine}
              alt="img"
              className="img-fluid d-md-block d-none"
            />
          </div>
          <div className="BottomRightCol2 col-lg-6  col-md-12 col-sm-12 order-md-2 order-1">
            <p className="my-4 mx-4 bottomText">
              Naast het super overzichtelijke televisie- en radio
              programmaoverzicht <b>bevat MAX Magazine wekelijks:</b>
            </p>
            {data.map((e) => {
              return (
                <Card className="py-4 px-3 my-3 mx-4 cards home_card_one bottomSecCard shadow">
                  <p className="d-flex mb-0 align-items-center">
                    <img src={GreenTick} className="mr-4 GreenTickIcon" />
                    <b>{e}</b>
                  </p>
                </Card>
              );
            })}

            <div
              className="text-center my-5  ml-4 d-md-none d-flex mr-auto     justify-content-center
"
            >
              <a
                href="#addPostCode"
                className=" btn btn-warning"
                // color="warning"
              >
                <span className="large-screen-text">
                  Controleer nu uw postcode
                </span>
                <i className="fa fa-solid fa-chevron-up mx-3"></i>{" "}
              </a>
            </div>
          </div>
        </Row>
        <div className="text-center my-3 d-md-block d-none">
          <a
            href="#addPostCode"
            className=" btn btn-warning"

            // color="warning"
          >
            <span className="large-screen-text">Controleer beschikbaarheid</span>
            <i className="fa fa-solid fa-chevron-up mx-3"></i>{" "}
          </a>
        </div>
      </Card>
    </div>
  )
}

export default BottomSecProefabonnement
